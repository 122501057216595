import React from "react";
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/react';
import { trimAddress } from "../hooks/constant";




export const Connect = function () {
    const { address, isConnected } = useAccount()
    const { open } = useWeb3Modal()


    return (
        <React.Fragment>

            <>
                {address && isConnected ? (

                    <button type="button" className="theme-btn" onClick={() => open()}>
                        {trimAddress(address)}
                    </button>

                ) : (
                    <button type="button" className="theme-btn" onClick={() => open()}>
                        Connect Wallet
                    </button>
                )
                }

            </>

        </React.Fragment >
    );
};

export default Connect;