import { BSC_CHAIN_ID, DEFAULT_RPC, contract } from '../hooks/constant';
import Multicall from '@dopex-io/web3-multicall';
import multiCallAbi from '../json/multicall.json';
import { getWeb3 } from "./connectors";



export const MulticallContractWeb3 = () => {
  const multicall = new Multicall({
    multicallAddress : contract[BSC_CHAIN_ID].MULTICALL_ADDRESS,
    provider : DEFAULT_RPC
  });

  return multicall;

}


export const multiCallContractConnect = () => {
  let web3 = getWeb3();
  return new web3.eth.Contract(multiCallAbi, contract[BSC_CHAIN_ID].MULTICALL_ADDRESS);
}
