import { ethers } from "ethers";
import { getWeb3 } from "./connectors";


export const getContract = (abi, address, library) => {
  try{
    return new ethers.Contract(address, abi, library)
  }
  catch{
    return false;
  }
}


export const formatPrice = (num , decimal = 8) => {
  return new Intl.NumberFormat('en-US' , {maximumSignificantDigits : decimal}).format(num);
}


export const getWeb3Contract = (abi, address, chainId) => {
  let web3 = getWeb3(chainId);
  return new web3.eth.Contract(abi, address);
}

export const mulDecimal = (amount, decimal) => {
  return ethers.utils.parseUnits(amount.toString(), decimal);
}

